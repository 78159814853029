.root {
	composes: g-grid from global;
	composes: g-grid-container from global;
	row-gap: 40px;
	column-gap: 32px;

	&.start {
		align-items: flex-start;
	}

	&.center {
		align-items: center;
	}
}

.content {
	display: flex;
	flex-direction: column;
	row-gap: 40px;
	grid-column: 1 / -1;

	@media (--medium-up) {
		z-index: 1;
		grid-row: 1 / -1;
		grid-column: 1 / 7;

		@nest .reverse & {
			grid-column: 7 / -1;
		}
	}

	@media (--large) {
		grid-column: 1 / 6;

		@nest .reverse & {
			grid-column: 8 / -1;
		}
	}
}

.intro {
	display: grid;
}

.image {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 7 / -1;
		grid-row: 1 / -1;
		display: flex;
		justify-content: flex-end;

		@nest .reverse & {
			grid-column: 1 / 7;
		}

		@nest .isImageLarge & {
			grid-column: 5 / -1;
		}

		@nest .isImageLarge.reverse & {
			grid-column: 1 / 9;
		}
	}

	& img {
		max-width: 100%;
		height: auto;
	}
}

.eyebrow {
	margin-bottom: var(--hdsplus-spacing-04);
}