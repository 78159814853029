.root {
	display: grid;
	gap: var(--hdsplus-spacing-12);
	composes: g-grid from global;
	composes: g-grid-container from global;
}

.headlineContainer {
	grid-column: 1 / -1;
	display: grid;

	@media (--medium-up) {
		grid-column: 1 / span 7;
	}
	@media (--large) {
		grid-column: 1 / span 6;
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}

.items {
	grid-column: 1 / -1;
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: grid;
	gap: var(--hdsplus-spacing-09);
	grid-template-columns: repeat(var(--mobileColumns), 1fr);
	row-gap: var(--hdsplus-spacing-08);

	@media (--medium-up) {
		grid-template-columns: repeat(var(--tabletColumns), 1fr);
		gap: var(--hdsplus-spacing-07);
		row-gap: var(--hdsplus-spacing-12);
	}

	@media (--large) {
		grid-template-columns: repeat(var(--desktopColumns), 1fr);
		gap: var(--hdsplus-spacing-08);
	}
}

.item {
	display: flex;
	flex-direction: column;
}

.quotee {
	color: var(--token-color-foreground-primary);
	font-size: 0.75rem; /* 12px, we do not have a --hdsplus-typography-body-100 */
	line-height: 0.75rem; /* 12px, we do not have a --hdsplus-typography-body-100 */

	@media (--large) {
		font-size: var(--hdsplus-typography-body-200-font-size);
		line-height: var(--hdsplus-typography-body-200-line-height);
	}
}

.quoteIcon {
	fill: url(#quoteGradient);
	margin-bottom: var(--hdsplus-spacing-04);

	@media (--medium-up) {
		margin-bottom: var(--hdsplus-spacing-06);
	}
}
