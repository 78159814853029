.twoUpPanel {
	composes: g-grid-container from global;
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-08);

	@media (--medium) {
		gap: var(--hdsplus-spacing-09);
	}

	@media (--large) {
		gap: var(--hdsplus-spacing-12);
	}
}

.headingContainer {
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-04);

	@media (--medium) {
		max-width: 560px;
	}

	@media (--large) {
		max-width: 520px;
	}
}

.panels {
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-07);

	@media (--large) {
		flex-direction: row;
	}
}

.promoPanelOuter {
	grid-column: 1 / -1;
	height: max-content;

	@media (--large) {
		width: calc(50% - var(--hdsplus-spacing-04));
		height: auto;
	}
}

.promoPanel {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	@media (--small) {
		height: min-content;
	}

	@media (--medium) {
		flex-direction: row;
		gap: var(--hdsplus-spacing-06);
	}

	@media (--large) {
		height: 100%;
	}
}

.thumbnail {
	position: relative;
	display: grid;
	overflow: hidden;
	aspect-ratio: 10 / 7;

	@media (--medium) {
		width: 46%;
		min-width: 46%;
	}
}

.image {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
}

.contentContainer {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
	position: relative;
	padding-top: var(--hdsplus-spacing-05);

	@media (--medium-up) {
		padding-top: 0;
		gap: var(--hdsplus-spacing-05);
	}

	@media (--large) {
		padding: var(--hdsplus-spacing-10) 0;
	}
}

.content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: var(--hdsplus-spacing-03);

	@media (--medium-up) {
		gap: var(--hdsplus-spacing-05);
	}
}

.headline {
	font-size: var(--hdsplus-typography-display-expressive-200-font-size);
	line-height: var(--hdsplus-typography-display-expressive-200-line-height);
	letter-spacing: var(
		--hdsplus-typography-display-expressive-200-letter-spacing
	);

	@media (--medium-up) {
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-300-letter-spacing
		);
	}

	@media (--large) {
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-400-letter-spacing
		);
	}
}

.body {
	@media (--medium-up) {
		font-family: var(--hdsplus-typography-body-200-font-family);
		font-size: var(--hdsplus-typography-body-200-font-size);
		line-height: var(--hdsplus-typography-body-200-line-height);
		letter-spacing: var(--hdsplus-typography-body-200-letter-spacing);
	}
}

.icon {
	height: 100%;
}

.cta {
	padding-top: var(--hdsplus-spacing-07);

	@media (--large) {
		padding-top: var(--hdsplus-spacing-09);
	}
}
