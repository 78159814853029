.root {
	--grid-column: span 6;

	grid-column: var(--grid-column);
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-06);

	@media (max-width: 767px) {
		--grid-column: span 4;
	}
}