.root {
	--borderRadius: var(--hdsplus-spacing-03);

	composes: g-grid from global;
	composes: g-grid-container from global;
	row-gap: var(--hdsplus-spacing-09);
	column-gap: var(--hdsplus-spacing-08);
	align-items: center;
}

.content {
	display: flex;
	flex-direction: column;
	row-gap: var(--hdsplus-spacing-09);
	grid-column: 1 / -1;

	@media (--medium-up) {
		z-index: 1;
		grid-row: 1 / -1;
		grid-column: 1 / 7;
	}

	@media (--large) {
		grid-column: 1 / span 5;
	}
}

.intro { 
	display: grid;
}

.eyebrow {
	font-weight: 600;
	margin-bottom: var(--hdsplus-spacing-04);
}

.heading {
	margin-bottom: var(--hdsplus-spacing-05);
	font-family: var(--hdsplus-typography-display-expressive-300-font-family);
	font-size: var(--hdsplus-typography-display-expressive-300-font-size);
	line-height: var(--hdsplus-typography-display-expressive-300-line-height);
	letter-spacing: var(--hdsplus-typography-display-expressive-300-letter-spacing);

	@media (--medium-up) {
		font-family: var(--hdsplus-typography-display-expressive-400-font-family);
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-400-letter-spacing);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-600-font-family);
		font-size: var(--hdsplus-typography-display-expressive-600-font-size);
		line-height: var(--hdsplus-typography-display-expressive-600-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-600-letter-spacing);
	}
}

.description {
	color: var(--token-color-foreground-faint);
	margin: 0;
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: 13px;
	font-weight: 400;
	line-height: 18px; 

	& p:first-of-type {
		margin-top: 0;
	}

	& p:last-of-type {
		margin-bottom: 0;
	}
	
	@media (--medium-up) {
		font-family: var(--hdsplus-typography-body-200-font-family);
		font-size: var(--hdsplus-typography-body-200-font-size);
		line-height: var(--hdsplus-typography-body-200-line-height);
		letter-spacing: var(--hdsplus-typography-body-200-letter-spacing);
	}
	
	@media (--large) {
		font-family: var(--hdsplus-typography-body-300-font-family);
		font-size: var(--hdsplus-typography-body-300-font-size);
		line-height: var(--hdsplus-typography-body-300-line-height);
		letter-spacing: var(--hdsplus-typography-body-300-letter-spacing);
	}
}

.ctas {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-05) var(--hdsplus-spacing-06);
	margin-top: var(--hdsplus-spacing-08);

	@media (--large) {
		margin-top: var(--hdsplus-spacing-09);
	}
}

.icon {
	height: 100%;
	display: flex;
}

.mobile {
	display: flex;

	@media (--medium-up) {
		display: none;
	}
}

.tablet {
	display: none;

	@media (--medium-up) {
		display: flex;
	}

	@media (--large) {
		display: none;
	}
}

.desktop {
	display: none;
	
	@media (--large) {
		display: flex;
	}
}

.ticket {
	background-color: var(--token-color-surface-primary);
	position: relative;
	display: grid;
	overflow: hidden;
	border-radius: var(--borderRadius);
	grid-column: 1 / -1;

	&.faint {
		background-color: var(--token-color-surface-faint);
	}
	
	@media (--large) {
		grid-column: 7 / -1;
	}
}

.border {
	position: absolute;
	inset: 0;
	pointer-events: none;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border: 1px solid var(--token-color-border-primary);
	border-radius: var(--borderRadius);
}

.notches {
	pointer-events: none;
	position: relative;
	grid-column: 1 / -1;
	grid-row: 1 / 2;
	width: 100%;
	height: 100%;

	&::before, &::after {
		--dimensions: 35px;
		--halvedDimensions: calc(-1 * (var(--dimensions) / 2) - 1px);
	
		content: '';
		pointer-events: none;
		position: absolute;
		width: var(--dimensions);
		height: var(--dimensions);
		border-radius: 100%;
		background-color: var(--token-color-surface-primary);
		border: 1px solid var(--token-color-border-primary);

		@nest .faint & {
			background-color: var(--token-color-surface-faint);
		}
	}

	&::before {
		bottom: var(--halvedDimensions);
		left: var(--halvedDimensions);
	}

	&::after {
		bottom: var(--halvedDimensions);
		right: var(--halvedDimensions);
	}
}

.detailsHeader {
	grid-column: 1 / -1;
	grid-row: 1 / 2;

	@nest :global(.hashidaysConference) & {
		background: linear-gradient(76deg, rgba(214, 234, 241, 0.00) 2.71%, rgba(2, 168, 239, 0.66) 86.37%, #A067DA 100%);				
	}
}

.detailsList {
	grid-column: 1 / -1;
	grid-row: 2 / 3;
}