.root {
	display: grid;
	padding-top: var(--hdsplus-spacing-08);
	row-gap: var(--hdsplus-spacing-10);

	@media (--medium-up) {
		grid-template-columns: 1fr 1fr;
		column-gap: var(--hdsplus-spacing-07);
		padding-top: var(--hdsplus-spacing-10);
	}
	
	@media (--large) {
		column-gap: var(--hdsplus-spacing-10);
	}
}

.logo {
	margin-bottom: var(--hdsplus-spacing-06);
	max-width: 125px;

	& img {
		max-width: 100%;
		height: auto;
	}

	@media (--medium-up) {
		margin-top: var(--hdsplus-spacing-06);
		margin-bottom: var(--hdsplus-spacing-07);
	}
}

.headline {
	font-family: var(--hdsplus-typography-display-expressive-200-font-family);
	font-size: var(--hdsplus-typography-display-expressive-200-font-size);
	line-height: var(--hdsplus-typography-display-expressive-200-line-height);
	letter-spacing: var(--hdsplus-typography-display-expressive-200-letter-spacing);
	font-weight: 700;
	color: var(--token-color-palette-neutral-700);
	margin: 0 0 var(--hdsplus-spacing-07);

	@media (--medium-up) {
		margin-bottom: var(--hdsplus-spacing-08);
		font-family: var(--hdsplus-typography-display-expressive-300-font-family);
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-300-letter-spacing);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-400-font-family);
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-400-letter-spacing);
	}
}

.cta {
	padding: 0;
	margin-bottom: var(--hdsplus-spacing-08);
	
	@media (--large) {
		margin-bottom: var(--hdsplus-spacing-10);
	}
}

.icon {
	display: flex;
	height: 100%;
}

.quote {
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: var(--hdsplus-typography-body-200-font-size);
	line-height: var(--hdsplus-typography-body-200-line-height);
	letter-spacing: var(--hdsplus-typography-body-200-letter-spacing);
	color: var(--token-color-palette-neutral-500);
	margin: 0 0 var(--hdsplus-spacing-05);

	@media (--large) {
		font-family: var(--hdsplus-typography-body-300-font-family);
		font-size: var(--hdsplus-typography-body-300-font-size);
		line-height: var(--hdsplus-typography-body-300-line-height);
		letter-spacing: var(--hdsplus-typography-body-300-letter-spacing);
	}
}

.quotee {
	display: grid;
	grid-template-columns: 40px 1fr; 
	align-items: center;
	gap: var(--hdsplus-spacing-04);
}

.quoteeImage {
	display: none;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	overflow: hidden;
	grid-column: 1 / 2;

	@media (--large) {
		display: block;
	}

	& img {
		max-width: 100%;
		height: auto;
	}
}

.quoteeName {
	grid-column: 1 / -1;
	font-family: var(--hdsplus-typography-label-font-family);
	font-size: var(--hdsplus-typography-label-font-size);
	line-height: var(--hdsplus-typography-label-line-height);
	letter-spacing: var(--hdsplus-typography-label-letter-spacing);
	margin: 0;
	color: var(--token-color-palette-neutral-400);
	text-transform: uppercase;
	font-weight: 600;

	@media (--large) {
		grid-column: 2 / 3;
	}
}

.image {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--hdsplus-spacing-03);
	overflow: hidden;
	border: 1px solid var(--token-color-palette-neutral-200);

	& img {
		max-width: 100%;
		height: 100%;
		object-fit: cover;
	}
}