.root {
	padding-top: var(--hdsplus-spacing-08);
	gap: var(--hdsplus-spacing-08);
	display: flex;
	flex-direction: column;

	@media (--medium-up) {
		padding-top: var(--hdsplus-spacing-10);
	}
}
