.pricingPanel {
	padding-top: var(--hdsplus-spacing-09);
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-14);
	max-width: 100%;
}

.pricingPanelContent {
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-12);
	max-width: 100%;
}

.contentHeading {
	text-align: center;
}

.tabsSection {
	composes: g-grid from global;
	row-gap: var(--hdsplus-spacing-10);

	& > * {
		grid-column: 1 / -1;
	}
}

.tiersFootnote {
	padding-top: var(--hdsplus-spacing-06);
	display: flex;
	justify-content: flex-end;
}

.intro {
	text-align: center;
}
