.root {
	--stat-grid-min-column-width: 0;

	composes: g-grid-container from global;
	composes: g-grid from global;

	&.three-columns {
		--stat-grid-column-count: 2;

		@media (--medium-up) {
			--stat-grid-column-count: 3;
		}
	}

	&.four-columns {
		--stat-grid-column-count: 2;
	
		@media (--large) {
			--stat-grid-column-count: 4;
		}
	}
}

.content {
	--grid-column: span 4;
	--grid-gap: var(--hdsplus-spacing-08);

	display: grid;
	grid-column: var(--grid-column);
	gap: var(--grid-gap);

	@media (--medium-up) {
		--grid-column: span 12;
		--grid-gap: var(--hdsplus-spacing-10);
	}
}

.group {
	display: grid;
	gap: var(--hdsplus-spacing-06);
}

.subheading {
	font-size: var(--hdsplus-typography-display-expressive-200-font-size);
	line-height: var(--hdsplus-typography-display-expressive-200-line-height);

	@media (--medium-up) {
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
	}
}