.root {
	composes: g-grid from global;
	composes: g-grid-container from global;
}

.modularEyebrow {
	grid-column: 1 / -1;
	margin-bottom: var(--hdsplus-spacing-04);

	@nest .center & {
		justify-content: center;
	}
}

.heading {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 2 / 12;

		@nest .left & {
			grid-column: 1 / span 6;
		}
	}

	@media (--large) {
		grid-column: 3 / 11;
	}
}

.productLogoSet {
	margin-top: var(--hdsplus-spacing-09);
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 4 / 10;
	}

	@nest .left & {
		grid-column: 1 / span 6;
	}
}
