.root {
	composes: g-grid from global;
	composes: g-grid-container from global;
	row-gap: var(--hdsplus-spacing-09);
	column-gap: var(--hdsplus-spacing-08);
	align-items: center;
}

.content {
	display: flex;
	flex-direction: column;
	row-gap: var(--hdsplus-spacing-09);
	grid-column: 1 / -1;

	@media (--medium-up) {
		z-index: 1;
		grid-row: 1 / -1;
		grid-column: 1 / 7;

		@nest .reverse & {
			grid-column: 7 / -1;
		}
	}

	@media (--large) {
		grid-column: 1 / 6;

		@nest .reverse & {
			grid-column: 8 / -1;
		}
	}
}

.intro {
	display: grid;
}

.description {
	margin-top: var(--hdsplus-spacing-05);
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: 13px;
	font-weight: 400;
	line-height: 18px;

	@media (--medium-up) {
		font-family: var(--hdsplus-typography-body-200-font-family);
		font-size: var(--hdsplus-typography-body-200-font-size);
		line-height: var(--hdsplus-typography-body-200-line-height);
		letter-spacing: var(--hdsplus-typography-body-200-letter-spacing);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-body-300-font-family);
		font-size: var(--hdsplus-typography-body-300-font-size);
		line-height: var(--hdsplus-typography-body-300-line-height);
		letter-spacing: var(--hdsplus-typography-body-300-letter-spacing);
	}
}

.ctas {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-05) var(--hdsplus-spacing-06);
	margin-top: var(--hdsplus-spacing-08);

	@media (--medium-up) {
		justify-content: flex-start;
	}

	@media (--large) {
		margin-top: var(--hdsplus-spacing-09);
	}
}

.icon {
	height: 100%;
	display: flex;
}

.mobile {
	display: flex;

	@media (--medium-up) {
		display: none;
	}
}

.tablet {
	display: none;

	@media (--medium-up) {
		display: flex;
	}

	@media (--large) {
		display: none;
	}
}

.desktop {
	display: none;

	@media (--large) {
		display: flex;
	}
}

.videoWrapper {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 7 / -1;
		grid-row: 1 / -1;
		display: flex;
		justify-content: flex-end;

		@nest .reverse & {
			grid-column: 1 / 7;
		}
	}
}

.video {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	/* Apply border radius to Wistia play button */
	& :global(.w-bpb-wrapper) {
		border-radius: var(--hdsplus-spacing-01) !important;
		overflow: hidden;
	}
}

.videoInner {
	width: 100%;
	height: 100%;
	aspect-ratio: 16 / 9;
	border-radius: var(--hdsplus-spacing-03);
	background-color: var(--token-color-foreground-faint);
	overflow: hidden;
	position: relative;
}

.eyebrow {
	margin-bottom: var(--hdsplus-spacing-04);
}
