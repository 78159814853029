.root {
	margin-top: var(--hdsplus-spacing-05);
	border: 1px solid var(--token-color-border-primary);
	border-radius: var(--hdsplus-spacing-03);
	overflow: hidden;
}

.inner {
	composes: g-grid from global;
	height: 100%;
	grid-template-rows: 181px 1fr;
	align-items: center;
	background-image: var(--mobileBackgroundImage);
	background-size: cover;
	
	@media (--medium-up) {
		background-image: var(--backgroundImage);
	}
}

.text {
	grid-column: 1 / -1;
	grid-row: 2 / -1;
	padding: var(--hdsplus-spacing-08) var(--hdsplus-spacing-06);

	@media (--medium-up) {
		grid-column: 1 / 10;
		grid-row: 1 / -1;
		padding: var(--hdsplus-spacing-13) var(--hdsplus-spacing-09);
	}

	@media (--large) {
		grid-column: 1 / 8;
		grid-row: 1 / -1;
		padding: var(--hdsplus-spacing-14) var(--hdsplus-spacing-11);
	}
}

.headline {
	display: block;
	font-family: var(--hdsplus-typography-display-expressive-300-font-family);
	font-size: var(--hdsplus-typography-display-expressive-300-font-size);
	line-height: var(--hdsplus-typography-display-expressive-300-line-height);
	letter-spacing: var(--hdsplus-typography-display-expressive-300-letter-spacing);
	margin-bottom: var(--hdsplus-spacing-06);
	font-weight: 600;

	@media (--large) {
		margin-bottom: var(--hdsplus-spacing-07);	
		font-family: var(--hdsplus-typography-display-expressive-400-font-family);
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-400-letter-spacing);
	}
}

.description {
	display: block;
	margin-bottom: var(--hdsplus-spacing-09);
	font-family: var(--hdsplus-typography-body-300-font-family);
	font-size: var(--hdsplus-typography-body-300-font-size);
	line-height: var(--hdsplus-typography-body-300-line-height);
	color: var(--token-color-foreground-faint);

	& ul {
		margin: 0 0 var(--hdsplus-spacing-04) 0;
		padding: 0;
		display: grid;
		row-gap: var(--hdsplus-spacing-03);
		list-style-type: none;
	}

	& li {
		--width: 17px;
		--horizontalPadding: 8px;

		position: relative;
		margin: 0;  
		
		&::before {
			position: absolute;
			top: 3px;
			left: 0;
			content: url(https://www.datocms-assets.com/2885/1698720142-listitem.svg);
			height: 20px;
			width: var(--width);
		}

		& p {
			margin: 0;
			padding-left: calc(var(--width) + var(--horizontalPadding));
		}
	}

	
	& p {
		margin: 0 0 var(--hdsplus-spacing-04) 0;
	}

	& *:last-child {
		margin-bottom: 0;
	}
}

.icon {
	height: 100%;
	display: flex;
}

.mobile {
	display: flex;

	@media (--medium-up) {
		display: none;
	}
}

.tablet {
	display: none;

	@media (--medium-up) {
		display: flex;
	}

	@media (--large) {
		display: none;
	}
}

.desktop {
	display: none;

	@media (--large) {
		display: flex;
	}
}
