.root {
	composes: g-grid from global;
	composes: g-grid-container from global;
	gap: var(--hdsplus-spacing-05);

	@media (--medium-up) {
		gap: var(--hdsplus-spacing-07);
	}

	@media (--large) {
		gap: var(--hdsplus-spacing-10);
	}

	&.secondary {
		& :global(.hds-tabs__tablist) {
			--token-color-foreground-action: var(--token-color-foreground-strong);
			--token-color-foreground-action-hover: var(
				--token-color-foreground-strong
			);
			--token-color-foreground-primary: var(--token-color-foreground-faint);
		}
	}
}

.heading {
	grid-column: 1/-1;

	&.left {
		@media (--medium-up) {
			grid-column: 1 / span 6;
		}
	}
}

.tabsContainer {
	grid-column: 1/-1;
}

.tabsList {
	&.center {
		width: fit-content;
		max-width: 100%;
		margin: 0 auto;
	}
}

.panels {
	display: grid;
}

.skeleton {
	display: flex;
	align-items: center;
	justify-content: center;
	grid-column: 1/-1;

	/* hack - viewport height minus the amount of space above the panel */
	height: calc(100vh - 284px);
}
