.root {
	margin: 0;
	padding: var(--hdsplus-spacing-08) 0 0 0;
	list-style-type: none;
	composes: g-grid from global;
	row-gap: var(--hdsplus-spacing-08);

	@media (--large) {
		row-gap: var(--hdsplus-spacing-07);
	}
}

.card {
	margin: 0;
	grid-column: 1 / -1;

	@media (--large) {
		grid-column: 6 / -1;

		&.isPrimary {
			grid-column: 1 / 6;
			grid-row: span 4;
		}
	}
}

.cardInner {
	display: grid;
	gap: var(--hdsplus-spacing-07);

	@media (--medium-up) {
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
	}

	@media (--large) {
		grid-template-columns: repeat(4, 1fr);
		
		@nest .isPrimary & {
			grid-template-columns: 1fr;
		}
	}
}

.image {
	border-radius: var(--spacing-spacing-03, 8px);
	overflow: hidden;
	display: flex; 
	justify-content: center;
	align-items: center;

	& img {
		max-width: 100%;
		height: auto;
		object-fit: cover;
	}

	@media (--large) {
		grid-column: span 1;

		@nest .isPrimary & {
			grid-column: 1 / -1;
		}
	}
}

.text {
	display: grid;
	
	@media (--medium-up) {
		row-gap: var(--hdsplus-spacing-03);
	}

	@media (--large) {
		grid-column: span 3;

		@nest .isPrimary & {
			grid-column: 1 / -1;
			row-gap: var(--hdsplus-spacing-05);
		}
	}
}

.headline {
	color: var(--token-color-foreground-strong);
	margin: 0;
	font-family: var(--hdsplus-typography-display-expressive-200-font-family);
	font-size: var(--hdsplus-typography-display-expressive-200-font-size);
	line-height: var(--hdsplus-typography-display-expressive-200-line-height);
	letter-spacing: var(--hdsplus-typography-display-expressive-200-letter-spacing);
	font-weight: 700;
	transition: color .4s;

	@nest .card:hover & {
		color: var(--token-color-foreground-primary);
	}


	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-100-font-family);
		font-size: var(--hdsplus-typography-display-expressive-100-font-size);
		line-height: var(--hdsplus-typography-display-expressive-100-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-100-letter-spacing);

		@nest .isPrimary & {
			font-family: var(--hdsplus-typography-display-expressive-300-font-family);
			font-size: var(--hdsplus-typography-display-expressive-300-font-size);
			line-height: var(--hdsplus-typography-display-expressive-300-line-height);
			letter-spacing: var(--hdsplus-typography-display-expressive-300-letter-spacing);
		}
	}
}

.description {
	margin: 0;
	color: #656A76;
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: var(--hdsplus-typography-body-200-font-size);
	line-height: var(--hdsplus-typography-body-200-line-height);


	@media (--large) {

		@nest .isPrimary & {
			font-family: var(--hdsplus-typography-body-300-font-family);
			font-size: var(--hdsplus-typography-body-300-font-size);
			line-height: var(--hdsplus-typography-body-300-line-height);
			letter-spacing: var(--hdsplus-typography-body-300-letter-spacing);
		}
	}
}
