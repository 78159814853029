.root {
	composes: g-grid from global;
	align-items: center;
	gap: var(--hdsplus-spacing-08);
	padding-top: var(--hdsplus-spacing-08);

	@media (--medium-up) {
		gap: var(--hdsplus-spacing-07);
		padding-top: var(--hdsplus-spacing-10);
	}
}

.text {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 1 / 6;
	}
}

.headline {
	color: var(--token-color-foreground-strong);
	margin: 0 0 var(--hdsplus-spacing-05) 0;
	font-family: var(--hdsplus-typography-display-expressive-300-font-family);
	font-size: var(--hdsplus-typography-display-expressive-300-font-size);
	line-height: var(--hdsplus-typography-display-expressive-300-line-height);
	letter-spacing: var(--hdsplus-typography-display-expressive-300-letter-spacing);

	@media (--large) {
		margin-bottom: var(--hdsplus-spacing-06);
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-400-letter-spacing);
	}
}

.description {
	color: var(--token-color-foreground-faint);
	margin: 0;
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: var(--hdsplus-typography-body-200-font-size);
	line-height: var(--hdsplus-typography-body-200-line-height);

	@media (--medium-up) {
		font-size: var(--hdsplus-typography-body-300-font-size);
		line-height: var(--hdsplus-typography-body-300-line-height);
	}
}

.cta {
	padding-top: var(--hdsplus-spacing-07);
	
	@media (--medium-up) {
		padding-top: var(--hdsplus-spacing-08);
	}
	
	@media (--large) {
		padding-top: var(--hdsplus-spacing-09);
	}
	
	& .mobile {
		display: flex;
	
		@media (--medium-up) {
			display: none;
		}
	}
	
	& .tablet {
		display: none;
	
		@media (--medium-up) {
			display: flex;
		}
	
		@media (--large) {
			display: none;
		}
	}
	
	& .desktop {
		display: none;
		
		@media (--large) {
			display: flex;
		}
	}
}

.image {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 7 / -1;
	}

	& img {
		max-width: 100%;
		height: auto;
	}
}