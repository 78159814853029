.root {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-03);
	&.hasCardStyle {
		padding: var(--hdsplus-spacing-05);
		background: var(--token-color-surface-faint);
		border: 1px solid var(--token-color-border-faint);
		border-radius: var(--hdsplus-spacing-04);

		& .link {
			margin-top: auto;
		}
	}
}

.value {
	font-family: var(--hdsplus-typography-display-expressive-600-font-family);
	font-size: var(--hdsplus-typography-display-expressive-600-font-size);
	line-height: var(--hdsplus-typography-display-expressive-600-line-height);

	& sup {
		vertical-align: baseline;
		font-size: inherit;
	}

	@media (--medium-up) {
		font-family: var(--hdsplus-typography-display-expressive-700-font-family);
		font-size: var(--hdsplus-typography-display-expressive-700-font-size);
		line-height: var(--hdsplus-typography-display-expressive-700-line-height);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-800-font-family);
		font-size: var(--hdsplus-typography-display-expressive-800-font-size);
		line-height: var(--hdsplus-typography-display-expressive-800-line-height);
	}
}

.description {
	font-family: var(--hdsplus-typography-display-expressive-100-font-family);
	font-size: var(--hdsplus-typography-display-expressive-100-font-size);
	line-height: var(--hdsplus-typography-display-expressive-100-line-height);

	@media (--medium-up) {
		font-family: var(--hdsplus-typography-display-expressive-200-font-family);
		font-size: var(--hdsplus-typography-display-expressive-200-font-size);
		line-height: var(--hdsplus-typography-display-expressive-200-line-height);
	}
}

.link {
	font-weight: 500;
}

.icon {
	display: block;
}