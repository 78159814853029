.root {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 32px;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.stat {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.statTitle {
	margin: 0;
	font-family: var(--hdsplus-typography-display-expressive-100-font-family);
	font-size: var(--hdsplus-typography-display-expressive-100-font-size);
	line-height: var(--hdsplus-typography-display-expressive-100-line-height);
	font-weight: 700;
	color: var(--token-color-foreground-strong);

	@media (--medium-up) {
		font-family: var(--hdsplus-typography-display-expressive-200-font-family);
		font-size: var(--hdsplus-typography-display-expressive-200-font-size);
		line-height: var(--hdsplus-typography-display-expressive-200-line-height);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-300-font-family);
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
	}
}

.statDescription {
	margin: 0;
	color: var(--token-color-foreground-faint);
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: 13px;
	line-height: 18px;

	@media (--large) {
		font-size: 16px;
		line-height: 20px;
	}
}
